import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import APaper from '../components/01-atoms/a-paper'
import MIconHeadlineTextLine from '../components/02-molecules/m-icon-headline-text-line'
import MHotline from '../components/02-molecules/m-hotline'
import OPackages from '../components/03-organisms/o-packages'
import TSimpleTemplateWrapper from '../components/04-templates/t-simple-template-wrapper'
import { globalNaming } from '../../site-config'

const styles = theme => ({
  upperContainer: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '40px',
    },
  },
})

/** PackagesPage */
class PackagesPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <TSimpleTemplateWrapper showHeadlineBar headline="Paketpreise">
        <APaper id="about-dlvz">
          <Grid
            container
            spacing={5}
            justify="center"
            className={classes.upperContainer}
          >
            <Grid item xs={12} sm={10}>
              <MIconHeadlineTextLine>
                <Typography variant="h2">
                  Folgende Pakete stehen Ihnen in unserem{' '}
                  {globalNaming.entryType}verzeichnis zur Verfügung
                </Typography>
              </MIconHeadlineTextLine>
            </Grid>
          </Grid>
          <OPackages />
        </APaper>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <MHotline />
          </Grid>
        </Grid>
      </TSimpleTemplateWrapper>
    )
  }
}

PackagesPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PackagesPage)
